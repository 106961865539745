@import '../../../styles/customMediaQueries.css';
.heroSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heroContainer {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/mobile-hero-banner-min.jpg');
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 24px;
  position: relative;
  min-height: 92vh;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    background-image: url('https://dih6yo2fd8n78.cloudfront.net/1.+HOME-min.jpg');
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: flex-end;
    padding: 35px 0px 100px;
    position: relative;
    min-height: calc(100dvh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (--viewportMedium) {
    background-position: center;
    &::before {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: var(--colorBlack);
      content: '';
      left: 0;
      top: 0;
      opacity: 0.2;
    }
  }

  & .contentWidth {
    position: relative;
    z-index: 1;
    max-width: 600px;
    width: 100%;
    margin: 0px auto;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      margin: 0px auto;
      padding: 0 24px;
    }

    & .heroContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: relative;
        z-index: 1;
      }

      & > img {
        max-width: 100%;
        width: 150px;

        @media (max-width: 767px) {
          display: none;
        }
      }

      & > h1 {
        color: var(--colorText);
        font-size: 30px;
        line-height: 35px;
        font-weight: var(--fontWeightBlack);
        letter-spacing: 0em;
        margin: 0px 0 0px 0;
        padding: 0;

        @media (max-width: 767px) {
          display: none;
        }

        @media (--viewportSmall) {
          font-size: 40px;
          line-height: 45px;
        }

        @media (--viewportMedium) {
          line-height: 57px;
          font-size: 50px;
          margin: 30px 0 0px 0;
        }

        @media (--viewportLarge) {
          line-height: 67px;
          font-size: 70px;
        }

        @media (--viewportLargeWithPaddings) {
          line-height: 87px;
          font-size: 80px;
        }
      }

      & p {
        color: var(--colorText);
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: var(--fontWeightBlack);
        line-height: 130%;
        letter-spacing: 0em;
        text-align: center;
        @media (max-width: 767px) {
          display: none;
        }
        @media (--viewportMedium) {
          margin: 10px 0 0 0;
          font-size: 25px;
        }

        @media (--viewportLarge) {
          font-size: 30px;
        }

        @media (--viewportLargeWithPaddings) {
          margin: 17px 0 0 0;
          font-size: 35px;
        }
      }

      & .bottomContent {
        margin-top: 10px;

        @media (--viewportMedium) {
          margin-top: 0px;
        }

        & > p {
          position: relative;

          &::after {
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 36px;
            height: 1px;
            display: inline-block;
            background-color: var(--colorGrey100);
            content: '';
          }
        }
      }

      & .keywords {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 16px;

        @media (--viewportLargeWithPaddings) {
          margin-top: 26px;
        }

        & .keyword {
          text-decoration: none;
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--colorText);
          margin-bottom: 8px;
          transition: var(--transitionStyle);

          @media (--viewportSmall) {
            margin-bottom: 10px;
          }

          @media (--viewportLargeWithPaddings) {
            margin-bottom: 15px;
          }

          &:not(:last-child) {
            margin-right: 14px;

            @media (--viewportSmall) {
              margin-right: 24px;
            }

            @media (--viewportMedium) {
              margin-right: 32px;
            }
          }

          &:hover {
            transition: var(--transitionStyle);
            color: var(--marketplaceColor);
          }
        }
      }

      & .heroSearchSec {
        width: 100%;

        @media (max-width: 767px) {
          position: relative;
          bottom: 170px;
        }
        @media (--viewportMedium) {
          padding: 0 32px;
          margin-top: 34px;
          background-color: transparent;
          border-radius: 0px;
          padding: 0px;
        }

        @media (--viewportLargeWithPaddings) {
          margin-top: 49px;
        }

        & .landingSearchForm {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          flex-direction: column;

          @media (max-width: 767px) {
            position: relative;
            flex-direction: row;
          }
          @media (max-width: 767px) {
            width: 100%;
            background-color: var(--colorText);
            border-radius: 100px;
            padding: 10px 14px 10px 20px;
          }

          @media (--viewportMedium) {
            padding: 13px;
            background-color: var(--colorText);
            border-radius: 100px;
            flex-direction: row;
          }

          & .locationSearch {
          }
        }
      }
    }
  }
}

.title {
  composes: h1 from global;
}

.sectionSubHeading {
  composes: sectionSubHeading from global;
}

.searchAllBtn {
  background-color: #3d632978;
  border: solid 2px var(--colorText);
  padding: 15px 40px;
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: var(--colorText);
  font-size: 20px;
  line-height: 130%;
  font-weight: var(--fontWeightBold);
  letter-spacing: 0.3px;
  margin-top: 36px;
  transition: var(--transitionStyle);

  @media (max-width: 767px) {
    background-color: transparent;
    border: solid 2px var(--colorText);
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 24px;
    min-width: 200px;
    transition: var(--transitionStyle);
    @media (--viewportSmall) {
      font-size: 15px;
      margin-top: 36px;
    }
    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColor);
      color: var(--colorText);
    }
  }

  &:hover {
    transition: var(--transitionStyle);
    text-decoration: none;
    background-color: var(--marketplaceColor);
    color: var(--colorText);
  }
}
