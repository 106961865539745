@import '../../../styles/customMediaQueries.css';

.locationElements {
  display: flex;
  gap: 10px;
}

.showGeneralAmenities {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 20px auto 0;
  padding: 0 24px;
  gap: 10px;

  @media (--viewportLarge) {
    gap: 30px;
  }

  & .amenityBlock {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 6);

    & > img {
      max-width: 100%;
      width: 150px;
    }

    & > p {
      margin: 0;
      margin-top: -20px;
      padding: 0;
      font-size: 15px;
      color: var(--colorText);
      font-weight: var(--fontWeightBold);
    }
  }
}

.imageGallary {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  flex-direction: column;

  &.imageGallary2 {
    & .gallarySec {
      order: 2;
    }

    & .sectionFilter {
      order: 1;
      padding-left: 0px;
      padding-right: 30px;

      @media (--viewportLarge) {
        padding-right: 60px;
      }
    }
  }

  & .gallarySec {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 14px;
    width: 100%;
    order: 2;

    @media (--viewportMedium) {
      grid-gap: 20px;
    }

    @media (--viewportLarge) {
      grid-gap: 24px;
    }

    & .imgBlock {
      border-radius: 10px;
      padding-bottom: 20%;
      position: relative;
      overflow: hidden;
      display: grid;
      transition: var(--transitionStyle);
      min-height: 150px;

      @media (--viewportMedium) {
        border-radius: 20px;
        padding-bottom: 30%;
      }

      &:hover {
        transition: var(--transitionStyle);

        & > img {
          transform: scale(1.05);
        }
      }

      & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        transition: var(--transitionStyle);
      }

      & > h2 {
        color: var(--colorText);
        font-size: 25px;
        line-height: 120%;
        text-align: center;
        word-wrap: break-word;
        width: 100%;
        font-weight: var(--fontWeightBlack);
        margin: 0 0 10px 0;
        padding: 0 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        word-wrap: break-word;
        text-shadow: 0px 0px 8px #000000a3;

        @media (--viewportSmall) {
          font-size: 35px;
        }
      }

      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 5;
      }

      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 5;
      }

      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 5;
        grid-row-end: 7;
      }
    }
  }

  & .sectionFilter {
    order: 1;
    width: 100%;
    margin-bottom: 15px;

    & > img {
      max-width: 100%;
      width: 150px;
      margin-bottom: 15px;
    }

    & > h2 {
      color: var(--colorText);
      font-size: 35px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;

      @media (--viewportMedium) {
        font-size: 50px;
      }
    }

    & > p {
      color: var(--colorText);
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;

      @media (--viewportSmall) {
        font-size: 20px;
      }
    }

    & > button {
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
      padding: 8px 16px;
      border-radius: 100px;
      display: inline-block;
      text-align: center;
      font-size: 15px;
      line-height: 120%;
      width: auto;
      min-width: 127px;
      min-height: 32px;

      @media (--viewportSmall) {
        font-size: 15px;
      }
    }
  }
}

.moreAbout {
  position: relative;
  overflow-x: hidden;
  padding: 50px 0px 0px;

  & .sectionListings {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;

    & .sectionFilter {
      width: 100%;
      padding: 0 24px;

      @media (--viewportLargeWithPaddings) {
        width: 50%;
      }

      & > img {
        max-width: 100%;
        width: 150px;
        margin-bottom: 15px;
      }

      & > h2 {
        color: var(--colorText);
        font-size: 35px;
        line-height: 100%;
        font-weight: var(--fontWeightBlack);
        margin: 0 0 10px 0;
        padding: 0;

        @media (--viewportMedium) {
          font-size: 50px;
        }
      }

      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        margin: 0 0 15px 0;
        padding: 0;
        text-align: justify;

        @media (--viewportSmall) {
          font-size: 20px;
        }
      }

      & > button {
        background-color: var(--marketplaceColorDark);
        border: none;
        box-shadow: var(--boxShadowNotFoundPageSearch);
        color: var(--colorText);
        padding: 15px 30px;
        border-radius: 100px;
        display: inline-block;
        text-align: center;
        font-size: 15px;
        line-height: 120%;
        width: auto;
        min-width: 200px;
        min-height: auto;

        @media (--viewportSmall) {
          font-size: 15px;
        }
      }
    }

    & .halfSlider {
      width: 100%;
      position: relative;
      right: 0;

      @media (--viewportLargeWithPaddings) {
        width: 100%;
      }

      & :global(.slick-slider) {
        position: initial;
        padding: 0 0 0 24px;
        display: flex;
        flex-direction: column;

        & :global(.slick-track) {
          display: flex;
        }

        & :global(.slick-dots) {
          position: relative;
          bottom: 0;
          background-color: var(--colorGrey500);
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
          width: auto;
          margin: 0 auto;
          border-radius: 100px;

          & > li {
            width: 40px;
            height: 40px;
            margin: 0;

            & > button {
              width: 40px;
              height: 40px;
              margin: 0;
              padding: 0;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &::before {
                width: 40px;
                height: 40px;
                font-size: 35px;
                color: var(--colorText);
                position: relative;
                line-height: 130%;
              }
            }
          }
        }

        & :global(.slick-list) {
          overflow: visible;
          /* margin-bottom: 20px; */
        }

        & :global(.slick-slide) {
          height: auto;

          & > div {
            height: 100%;

            & > div {
              height: 100%;
            }
          }

          & :global(.slick-slider) {
            padding: 0;
            position: relative;
            display: block;

            & :global(.slick-dots) {
              position: absolute;
              bottom: 20px;
              background-color: transparent;
              display: block !important;
              width: 100%;
              margin: 0;
              border-radius: 0px;

              & > li {
                width: 10px;
                height: 10px;
                margin: 0 4px;

                & > button {
                  width: 10px;
                  height: 10px;
                  margin: 0;
                  padding: 0;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  &::before {
                    width: 10px;
                    height: 10px;
                    font-size: 10px;
                    color: var(--colorGrey500);
                    position: relative;
                    line-height: 130%;
                  }
                }
              }

              & :global(.slick-active) {
                & > button {
                  &::before {
                    color: var(--marketplaceColor);
                  }
                }
              }
            }

            & :global(.slick-list) {
              overflow: hidden;
              margin-bottom: 0;
            }

            & :global(.slick-arrow) {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 100px;
              z-index: 1;
              width: 34px;
              height: 34px;
            }

            & :global(.slick-prev > svg) {
              transform: rotate(0deg);
            }

            /* & :global(.slick-next > svg) {
              transform: rotate(180deg);
            } */
            & :global(.slick-prev) {
              left: 10px;
            }

            & :global(.slick-next) {
              right: 10px;
            }

            & :global(.slick-next:hover),
            & :global(.slick-next:focus),
            & :global(.slick-prev:hover),
            & :global(.slick-prev:focus) {
              background-color: var(--colorText);
            }

            & :global(.slick-slide) {
              padding: 0;
            }
          }
        }
      }

      & :global(.slick-prev),
      & :global(.slick-next) {
        width: 50px;
        height: 50px;
        background-color: var(--colorText);
      }

      & :global(.slick-arrow) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100px;
        z-index: 1;
      }

      & :global(.slick-slide) {
        padding: 0 8px;

        @media (--viewportLarge) {
          padding: 0 10px;
        }
      }

      & :global(.slick-prev > svg) {
        transform: rotate(0deg);
      }

      /* & :global(.slick-next > svg) {
        transform: rotate(180deg);
      } */
      & :global(.slick-prev) {
        left: 30px;
      }

      & :global(.slick-next) {
        right: 30px;
      }

      & :global(.slick-next:hover),
      & :global(.slick-next:focus),
      & :global(.slick-prev:hover),
      & :global(.slick-prev:focus) {
        background-color: var(--colorText);
      }

      & :global(.slick-prev:before),
      & :global(.slick-next:before) {
        font-size: 30px;
      }
    }
  }
}

.ourPartnerSection + .fullSlider {
  margin-top: 50px;
}

.ourPartnerSection {
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 50px auto 0;
  text-align: center;
  padding: 0px 24px;

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    line-height: 100%;
    font-weight: var(--fontWeightBlack);
    margin: 0px 0 10px 0;
    padding: 0;
    padding: 0;
    text-align: left;

    @media (--viewportMedium) {
      font-size: 50px;
    }
  }

  & .seenOn {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 15px;
    padding-bottom: 14px;
    gap: 25px;

    & .partnerImg {
      background-color: var(--colorText);
      border-radius: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: calc(100% / 6);
      min-height: 90px;
      min-width: 90px;

      @media (--viewportLarge) {
        width: calc(100% / 6 - 40px);
      }

      & > img {
        max-width: 100%;
        width: 100px;
      }
    }

    & .listingImg {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: calc(100% / 6);
      background-color: var(--colorText);
      border-radius: 20px;
      min-height: 107px;
      min-width: 100px;
      padding: 10px;

      & > img {
        max-width: 100%;
        width: 60px;

        @media (--viewportMedium) {
          width: 100px;
        }
      }

      & > h2 {
        color: var(--marketplaceColorDark);
        font-size: 14px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        margin: 0;
        padding: 0;
        margin-top: -5px;

        @media (--viewportSmall) {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  :global(.slick-slider) {
    margin: 0 -10px;

    @media (--viewportLarge) {
      margin: 0 -10px;
    }
  }

  :global(.slick-slide) {
    padding: 10px 10px;

    @media (--viewportLarge) {
      padding: 10px 10px;
    }
  }

  :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: var(--colorText);
    box-shadow: var(--boxShadow);
    transition: var(--transitionStyle);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  :global(.slick-prev) {
    left: 10px;
  }

  :global(.slick-prev:before) {
    content: ' ';
  }

  :global(.slick-next) {
    right: 10px;
  }

  :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  /* :global(.slick-next > svg) {
    transform: rotate(180deg);
  } */
  :global(.slick-next:before) {
    content: ' ';
  }

  :global(.slick-prev:before),
  :global(.slick-next:before) {
    font-size: 30px !important;
    box-shadow: none;
    background-color: transparent;
    padding: 0px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
  }

  :global(.slick-next:hover),
  :global(.slick-next:focus),
  :global(.slick-prev:hover),
  :global(.slick-prev:focus) {
    background-color: var(--colorText);
  }
}

.fullSlider {
  width: 100%;
  position: relative;
  overflow-x: hidden;

  & :global(.slick-slider) {
    position: initial;
    padding: 0 0 0 24px;
  }

  & :global(.slick-list) {
    overflow: visible;
  }

  & :global(.slick-arrow) {
    width: 50px;
    height: 50px;
    background-color: var(--colorText);
    border-radius: 100px;
  }

  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  /* & :global(.slick-next > svg) {
    transform: rotate(180deg);
  } */
  & :global(.slick-prev) {
    left: 30px;
  }

  & :global(.slick-next) {
    right: 30px;
  }

  & :global(.slick-next:hover),
  & :global(.slick-next:focus),
  & :global(.slick-prev:hover),
  & :global(.slick-prev:focus) {
    background-color: var(--colorText);
  }
}

.sliderItem {
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--boxShadowNotFoundPageSearch);
  min-height: 350px;

  @media (--viewportSmall) {
    min-height: 400px;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  & .sliderItemContent {
    position: absolute;
    background-color: #6ea36099;
    width: 100%;
    border-radius: 20px;
    padding: 14px 20px;
    bottom: 0;
    left: 0;
    text-align: center;

    & > h2 {
      color: var(--colorText);
      font-size: 35px;
      font-weight: var(--fontWeightBlack);
      line-height: 120%;
      padding: 0;
      margin: 0 0 4px 0;

      @media (--viewportSmall) {
        font-size: 35px;
      }
    }

    & > p {
      color: var(--colorText);
      font-size: 20px;
      line-height: 120%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 13px 0;
      padding: 0;
      text-align: justify;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;

      & > span {
        font-weight: 600;
      }

      @media (--viewportSmall) {
        font-size: 20px;
      }
    }

    & > button {
      background-color: transparent;
      border: solid 2px var(--colorText);
      color: var(--colorText);
      font-size: 14px;
      line-height: 120%;
      padding: 8px 20px;
      display: inline-block;
      text-align: center;
      width: auto;
      min-height: auto;
      height: auto;
      border-radius: 100px;

      @media (--viewportSmall) {
        font-size: 14px;
      }
    }
  }
}

.howItWorksContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 130%;
    padding: 0;
    margin: 0 0 4px 0;
    text-align: center;
  }

  & .locationElements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-bottom: 30px;

    & .location {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & .imageWrapper {
        & > img {
          max-width: 100%;
          width: 200px;
        }
      }

      & > h2 {
        margin: 0 0 25px 0;
        line-height: 100%;
        padding: 0;
        color: var(--marketplaceColorLightBg);
        font-size: 25px;
        font-weight: var(--fontWeightBlack);
      }

      & > p {
        color: var(--colorText);
        font-size: 12px;
        line-height: 120%;
        text-align: justify;
        font-weight: var(--fontWeightRegular);
        margin: 0;
        padding: 0;
      }
    }
  }

  & .searchBtn {
    background-color: #3d632978;
    border: solid 2px var(--colorText);
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 36px;
    transition: var(--transitionStyle);

    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColor);
      color: var(--colorText);
    }
  }
}

.campsiteContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;

    @media (--viewportMedium) {
      font-size: 50px;
    }
  }

  & > p {
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
    padding: 0;
    max-width: 50%;
    width: 100%;
    text-align: center;
    margin: 0 auto 10px;
  }

  & .searchBtn {
    background-color: #3d63299e;
    border: solid 2px #3d63299e;
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: auto;
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 20px;
    transition: var(--transitionStyle);
    min-width: 200px;

    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColor);
      color: var(--colorText);
    }
  }
}

.becomeHostContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;

    @media (--viewportMedium) {
      font-size: 50px;
    }
  }

  & .featureSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    margin: 50px 0;

    & .featureBlock {
      text-align: center;
      width: 100%;

      & > svg {
        margin-bottom: 20px;
      }

      & > h2 {
        color: var(--marketplaceColorLightBg);
        font-size: 25px;
        font-weight: var(--fontWeightBlack);
        line-height: 120%;
        padding: 0;
        margin: 0 0 12px 0;
      }

      & > p {
        color: var(--colorText);
        font-size: 15px;
        line-height: 150%;
        font-weight: var(--fontWeightRegular);
        padding: 0;
        max-width: 80%;
        width: 100%;
        text-align: center;
        margin: 0 auto 10px;
      }
    }
  }

  & .bottomButtonsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

    & > button {
      background-color: #3d63299e;
      border: solid 2px var(--colorText);
      padding: 15px 20px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      color: var(--colorText);
      font-size: 15px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 20px;
      transition: var(--transitionStyle);

      @media (--viewportLarge) {
        padding: 15px 40px;
      }

      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }

      &.whiteBorderBtn {
        background-color: transparent;
        color: var(--colorText);

        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }
  }
}

.newsletterSec {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;

    @media (--viewportMedium) {
      font-size: 50px;
    }
  }

  & .newsletterForm {
    max-width: 60%;
    width: 100%;
    margin: 20px auto 0;

    & input {
      background-color: var(--colorText);
      border-radius: 100px;
      padding: 0px 30px;
      min-height: 60px;
      margin-bottom: 20px;
    }

    & > button {
      background-color: transparent;
      color: var(--colorText);
      border: solid 2px var(--colorText);
      padding: 15px 40px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      font-size: 15px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 20px;
      transition: var(--transitionStyle);
      min-width: 200px;

      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }
    }
  }
}

.campingInContent {
  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto 47px;
    text-align: center;

    @media (--viewportMedium) {
      font-size: 50px;
    }
  }

  & :global(.slick-arrow) {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: var(--colorText);
  }

  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  /* & :global(.slick-next > svg) {
    transform: rotate(180deg);
  } */
  & :global(.slick-prev) {
    left: 30px;
  }

  & :global(.slick-next) {
    right: 30px;
  }

  & :global(.slick-next:hover),
  & :global(.slick-next:focus),
  & :global(.slick-prev:hover),
  & :global(.slick-prev:focus) {
    background-color: var(--colorText);
  }

  & .sliderItem {
    padding-bottom: 66.66%;
    position: relative;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: var(--transitionStyle);

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: var(--transitionStyle);
    }

    & > h2 {
      color: var(--colorText);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-shadow: 0px 0px 8px #000000a3;
    }
  }
}

.parkingSpaces {
  position: relative;
  z-index: 1;
  height: 100%;

  & :global(.slick-slider) {
    height: 100%;
    margin: 0;
    position: relative;
  }

  & :global(.slick-list) {
    height: 100%;
    position: initial;
  }

  & :global(.slick-track) {
    height: 100%;
    position: initial;
    display: flex;
  }

  & :global(.slick-slide) {
    display: flex;
    padding: 0;
    height: auto;
    flex-direction: column;
  }

  & :global(.slick-slide > div) {
    height: 100%;
  }

  & :global(.slick-arrow) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 38px;
    height: 38px;
    z-index: 1;
    bottom: 110px;
    top: auto;
    transform: none;

    &::before {
      display: none;
    }
    & > svg {
      /* width: 20px;
      height: 20px; */
    }
  }

  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  /* & :global(.slick-next > svg) {
    transform: rotate(180deg);
  } */
  & :global(.slick-prev) {
    left: 20px;
  }

  & :global(.slick-next) {
    right: 20px;
  }

  & :global(.slick-next:hover),
  & :global(.slick-next:focus),
  & :global(.slick-prev:hover),
  & :global(.slick-prev:focus) {
    background-color: var(--colorText);
  }

  & .parkingSliderItem {
    height: 100dvh;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 0px;

    @media (--viewportSmall) {
      padding: 0px;
    }

    & > img {
      position: absolute;
      z-index: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    & .itemContent {
      position: relative;
      z-index: 1;
      padding: 50px 24px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (--viewportSmall) {
        padding: 50px 24px;
      }

      & .heading {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;

        & > img {
          width: 80px;
          height: 80px;
          max-height: 100%;
          margin-right: 20px;
        }

        & > span {
          color: var(--colorText);
          font-size: 35px;
          line-height: 100%;
          font-weight: var(--fontWeightBlack);
          width: 100%;

          @media (--viewportMedium) {
            font-size: 50px;
          }
        }
      }

      & > h1 {
        color: var(--colorText);
        font-size: 25px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        max-width: 100%;
        width: 100%;

        @media (--viewportSmall) {
          font-size: 30px;
        }

        @media (--viewportMedium) {
          font-size: 40px;
        }

        & > span {
          font-weight: var(--fontWeightBold);
        }
      }

      & > h2 {
        color: var(--colorText);
        font-size: 30px;
        line-height: 130%;
        font-weight: var(--fontWeightBlack);
        width: 100%;

        @media (--viewportMedium) {
          font-size: 50px;
        }

        & > span {
          font-weight: var(--fontWeightBold);
        }
      }

      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        width: 100%;
        margin-top: 5px;

        @media (--viewportSmall) {
          font-size: 25px;
        }
      }
    }

    & .bottomContent {
      text-align: center;
      background-color: var(--marketplaceColorLightBg);
      padding: 20px;
      position: relative;
      z-index: 1;

      & > h2 {
        font-size: 25px;
        color: var(--colorText);
        font-weight: var(--fontWeightBlack);
        line-height: 130%;
        padding: 0;
        margin: 0 0 20px 0;
      }

      & > p {
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 20px;
        color: var(--colorText);
        font-weight: var(--fontWeightRegular);
      }

      & > button {
        background-color: #3d632978;
        border: none;
        padding: 15px 30px;
        border-radius: 100px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        width: auto;
        color: var(--colorText);
        font-size: 15px;
        line-height: 130%;
        font-weight: var(--fontWeightBold);
        letter-spacing: 0.3px;
        transition: var(--transitionStyle);
        width: auto;
        position: relative;
        z-index: 1;
        align-self: flex-start;
        min-width: 200px;

        @media (--viewportSmall) {
          font-size: 15px;
        }

        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }
  }

  & .searchBtnSec {
    display: flex;
    align-items: center;
    justify-content: center;
    & .whiteBorderBtn {
      border: solid 2px var(--colorText);
      background-color: transparent;
      color: var(--colorText);
      padding: 15px 20px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      color: var(--colorText);
      font-size: 15px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 20px;
      transition: var(--transitionStyle);

      @media (--viewportLarge) {
        padding: 15px 40px;
      }

      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }
    }
  }

  & .searchBtn {
    background-color: var(--marketplaceColor);
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 15px;
    transition: var(--transitionStyle);

    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
    }
  }
}

.becomeHostSection {
  position: relative;
  z-index: 1;
  height: 100%;

  & :global(.slick-slider) {
    height: 100%;
    margin: 0;
    position: relative;
  }

  & :global(.slick-list) {
    height: 100%;
    position: initial;
  }

  & :global(.slick-track) {
    height: 100%;
    position: initial;
    display: flex;
    align-items: stretch;
  }

  & :global(.slick-slide) {
    display: flex;
    padding: 0;
    height: auto;
    flex-direction: column;
  }

  & :global(.slick-slide > div) {
    height: 100%;
  }

  & :global(.slick-arrow) {
    bottom: 110px;
    top: auto;
    transform: none;
  }

  & :global(.slick-arrow) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 38px;
    height: 38px;
    z-index: 1;

    &::before {
      display: none;
    }
    & > svg {
      /* width: 20px;
      height: 20px; */
    }
  }

  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  /* & :global(.slick-next > svg) {
    transform: rotate(180deg);
  } */
  & :global(.slick-prev) {
    left: 20px;
  }

  & :global(.slick-next) {
    right: 20px;
  }

  & :global(.slick-next:hover),
  & :global(.slick-next:focus),
  & :global(.slick-prev:hover),
  & :global(.slick-prev:focus) {
    background-color: var(--colorText);
  }

  & .parkingSliderItem {
    height: 100dvh;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 0px;

    @media (--viewportSmall) {
      padding: 0px;
    }

    & > img {
      position: absolute;
      z-index: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    & .itemContent {
      position: relative;
      z-index: 1;
      padding: 50px 24px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (--viewportSmall) {
        padding: 50px 24px;
      }

      & > svg {
        width: 40px;
        height: 40px;
      }

      & .heading {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & > a {
          width: 80px;
          height: 80px;
          margin-right: 20px;

          & > div {
            width: 100%;
            height: 100%;

            & > img {
              width: 100%;
              height: 100%;
              max-height: 100%;
            }
          }
        }

        & > span {
          font-size: 30px;
          font-weight: var(--fontWeightBlack);
          color: var(--colorText);
          text-transform: uppercase;

          @media (--viewportMedium) {
            font-size: 40px;
          }
        }
      }

      & > h1 {
        color: var(--colorText);
        font-size: 35px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        max-width: 100%;
        width: 100%;
        margin: 15px 0 5px;

        @media (--viewportMedium) {
          font-size: 50px;
          max-width: 70%;
        }

        & > span {
          font-weight: var(--fontWeightBold);
        }
      }

      & > h2 {
        color: var(--colorText);
        font-size: 35px;
        line-height: 130%;
        font-weight: var(--fontWeightBlack);
        width: 100%;
        margin: 15px 0 5px;

        @media (--viewportMedium) {
          font-size: 50px;
        }

        & > span {
          font-weight: var(--fontWeightBold);
        }
      }

      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        width: 100%;
        margin-top: 5px;

        @media (--viewportSmall) {
          font-size: 20px;
        }

        & > span {
          font-weight: var(--fontWeightBold);
        }
      }

      & > button {
        background-color: #3d632978;
        border: none;
        padding: 15px 30px;
        border-radius: 100px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        width: auto;
        color: var(--colorText);
        font-size: 15px;
        line-height: 130%;
        font-weight: var(--fontWeightBold);
        letter-spacing: 0.3px;
        margin-top: 20px;
        transition: var(--transitionStyle);
        width: auto;
        position: relative;
        z-index: 1;
        align-self: flex-start;
        min-width: 200px;

        @media (--viewportSmall) {
          font-size: 15px;
        }

        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }

    & .bottomContent {
      text-align: center;
      background-color: var(--marketplaceColorLightBg);
      padding: 50px;
      position: relative;
      z-index: 1;

      & > h2 {
        font-size: 35px;
        color: var(--colorText);
        font-weight: var(--fontWeightBlack);
        line-height: 100%;
        padding: 0;
        margin: 0 0 15px 0;

        @media (--viewportMedium) {
          font-size: 50px;
        }
      }

      & > p {
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 20px;
        line-height: 130%;
        color: var(--colorText);
        font-weight: var(--fontWeightRegular);
      }

      & .consultationBtn {
        background-color: #3d632978;
        border: none;
        padding: 15px 30px;
        border-radius: 100px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        width: auto;
        color: var(--colorText);
        font-size: 15px;
        line-height: 130%;
        font-weight: var(--fontWeightBold);
        letter-spacing: 0.3px;
        transition: var(--transitionStyle);
        width: auto;
        position: relative;
        z-index: 1;
        align-self: flex-start;

        @media (--viewportSmall) {
          font-size: 15px;
        }

        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }
  }

  & .bottomButtonsWrapper {
    display: flex;
    flex-direction: column;

    & > button {
      background-color: #3d63299e;
      border: solid 2px var(--colorText);
      padding: 15px 20px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      color: var(--colorText);
      font-size: 15px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 20px;
      transition: var(--transitionStyle);

      @media (--viewportLarge) {
        padding: 15px 40px;
      }

      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }

      &.whiteBorderBtn {
        background-color: transparent;
        color: var(--colorText);

        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }
  }
}

.listingImgMobile {
  background-color: var(--colorText) !important;
  border-radius: 20px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 15px !important;
  width: calc(100% / 6 - 24px) !important;
  min-height: 90px !important;
  min-width: 90px !important;
}

.exploreNowButton {
  padding: 0 24px;
  margin-top: 24px;
  & button {
    background-color: var(--marketplaceColorDark);
    color: var(--colorText);
    padding: 8px 16px;
    border-radius: 100px;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    line-height: 120%;
    width: auto;
    min-width: 127px;
    min-height: 32px;

    @media (--viewportSmall) {
      font-size: 15px;
    }
  }
}

.seoHeading {
  & h2 {
    color: var(--colorText);
    font-size: 34px;
    line-height: 100%;
    font-weight: var(--fontWeightBlack);
    margin: 0px 0px 10px 0px;
    padding: 0 24px;
    text-align: start;
    @media (max-width: 768px) {
      margin: 0px 0px 10px 0px;
    }
  }
}
