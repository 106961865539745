@import '../../../../styles/customMediaQueries.css';
.root {
}
.field {
  flex-grow: 1;
  text-align: left;
  padding: 0px 10px;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    padding: 0px 25px;
    margin-bottom: 0px;
  }

  & > label {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 15px;
    font-weight: var(--fontWeightMedium);
    line-height: 22px;
    letter-spacing: 0.02em;
    color: var(--textColor);

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  & > input {
    min-height: 50px;
    box-shadow: none;
    color: var(--textColor);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: var(--colorGrey50);
    border: solid 1px var(--colorGrey100);
    margin: 0 0 5px 0;

    @media (--viewportMedium) {
      min-height: auto;
      padding: 0;
      background-color: transparent;
      border: none;
      margin: 0;
    }

    &::placeholder {
      opacity: 1;
      color: var(--textLightColor);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.heroSearchBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: var(--colorText);
  flex-direction: column;
  padding: 20px;

  @media (--viewportSmall) {
    padding: 0 30px;
    flex-direction: row;
  }

  & .findAbout {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (--viewportSmall) {
      padding-right: 24px;
      justify-content: flex-start;
      width: auto;
      min-width: 190px;
    }

    & > svg {
      margin-right: 12px;
      transform: rotate(270deg);
      stroke: var(--colorBlack);
      width: 16px;
      height: 16px;
    }
  }

  & .heroSearch {
    flex-grow: 1;
    padding: 0px 10px;
    width: 100%;
    border: solid 1px var(--colorGrey100);
    margin: 10px 0;

    @media (--viewportSmall) {
      padding: 16px 10px;
      margin: 0;
      border: none;
      border-left: solid 1px var(--colorGrey100);
      width: auto;
    }

    & > div {
      display: flex;

      & > div {
        width: 100%;
        text-align: left;
        border: none !important;
        box-shadow: none;
        border-radius: 0;
        cursor: pointer;

        & input {
          border: none;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }

  & .submitButton {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
    border: solid 1px var(--marketplaceColor);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--transitionStyle);
    width: 100%;

    @media (--viewportSmall) {
      width: auto;
    }

    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--marketplaceColorDark);
      border: solid 1px var(--marketplaceColorDark);
      color: var(--colorText);
    }
  }
}

.landingLocationSearch {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  width: 100%;

  @media (--viewportLarge) {
    margin-right: 12px;
    width: auto;
  }
}

.searchBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border-radius: 100px;
  text-align: center;
  background-color: var(--marketplaceColor);
  border: none;
  transition: var(--transitionStyle);
  color: var(--colorText);
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  height: 100%;
  padding: 12px;

  @media (--viewportMedium) {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 30px;
    border-radius: 100px;
  }

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--colorText);
    border: none;
  }

  &:disabled {
    color: var(--colorText);
    background-color: var(--marketplaceColor);
  }

  & > svg {
    margin-right: 10px;

    & path {
      fill: var(--colorText);
    }
  }
}

.guestsWrapper {
  position: relative;
  margin-top: 12px;
  width: 100%;

  @media (--viewportLarge) {
    margin-right: 12px;
    margin-top: 0px;
    width: auto;
  }

  & .guestsBtn {
    background-color: var(--colorText);
    border-radius: 7px;
    border: none;
    padding: 10px 10px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 20px;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 20px;
      padding: 14px 16px 14px 12px;
    }

    & > svg {
      margin-right: 8px;
      width: 18px;

      @media (--viewportMedium) {
        width: 20px;
      }
    }
  }
}

.guestsDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 9px;
  width: 100%;
  background-color: var(--colorText);
  min-width: 260px;
  z-index: 1;
  border: 1px solid #d8dce1;
  box-shadow: var(--boxShadowFilterButton);
  text-align: left;
  border-radius: 4px;
  padding: 20px;

  & .choosePassangers {
    margin-bottom: 20px;

    & .passangerType {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      & .passangerCount {
        display: flex;
        align-items: center;

        & > * {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        & .totalPersons {
          font-weight: var(--fontWeightSemiBold);
          color: var(--colorBlack);
          font-size: 15px;
          line-height: 100%;
        }

        & .personCategory {
          font-weight: var(--fontWeightRegular);
          color: var(--colorBlack);
          font-size: 15px;
          line-height: 100%;
        }
      }

      & .selectBox {
        display: inline-flex;
        align-items: center;

        &.checkBox {
          & > span {
            padding: 0;

            & > label {
              margin: 0;
              padding-right: 5px;

              & > span {
                margin-right: 0px;
              }
            }
          }
        }

        & > * {
          margin-right: 5px;
          padding: 0px 8px;
          color: var(--marketplaceColor);
          font-weight: 600;

          &:last-child {
            margin-right: 0;
          }
        }

        & > button {
          width: 30px;
          height: 30px;
          border-radius: 100px;
          cursor: pointer;
          border: solid 1px var(--marketplaceColor);
          background-color: var(--colorText);
          color: var(--marketplaceColor);
          transition: var(--transitionStyle);

          &:hover {
            background-color: var(--marketplaceColorDark);
            border: solid 1px var(--marketplaceColorDark);
            color: var(--colorText);
            transition: var(--transitionStyle);
          }
        }
      }
    }
  }

  & .applyBtn {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 7px 20px;
    font-weight: var(--fontWeightSemiBold);
    transition: var(--transitionStyle);
    float: right;
    cursor: pointer;

    &:hover {
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
    }
  }
}

.travelDateSection {
  width: 100%;
  max-width: 100%;
  margin-top: 12px;

  @media (--viewportLarge) {
    max-width: 40%;
    margin-top: 0px;
  }
}

.searchPageFilter {
  & .landingLocationSearch {
    place-content: center flex-start;
    align-items: center;
    background-color: rgb(243, 243, 242);
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    gap: 0px;
    height: 48px;
    overflow: visible;
    padding: 12px 16px;
    position: relative;
    width: 100%;

    @media (--viewportLarge) {
      width: 240px;
    }

    & > input {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
    }

    & > div {
      &:first-child {
        display: none;
      }
    }
  }

  & .travelDateSection {
    & div {
      &::before {
        opacity: 0.5;
      }

      & input {
        place-content: center flex-start;
        align-items: center;
        background-color: rgb(243, 243, 242);
        border-radius: 12px;
        display: flex;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        gap: 0px;
        height: 48px;
        overflow: visible;
        padding: 12px 16px 12px 42px;
        position: relative;
      }
    }
  }

  & .guestsWrapper {
    & .guestsBtn {
      place-content: center flex-start;
      align-items: center;
      background-color: rgb(243, 243, 242);
      border-radius: 12px;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      gap: 0px;
      height: 48px;
      overflow: visible;
      padding: 12px 16px;
      position: relative;

      & > svg {
        margin-right: 10px;
        opacity: 0.5;
      }
    }
  }

  & .searchIcon {
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--marketplaceColor);
    border: solid 1px var(--marketplaceColor);
    transition: var(--transitionStyle);
    padding: 10px 20px;
    margin-top: 24px;

    @media (--viewportLarge) {
      width: 46px;
      height: 46px;
      margin-top: 0px;
      min-height: auto;
      border-radius: 100px;
      padding: 0;
    }

    &:hover {
      background-color: var(--marketplaceColorDark);
      border-color: var(--marketplaceColorDark);

      & > svg {
        transition: var(--transitionStyle);

        & circle {
          transition: var(--transitionStyle);
          stroke: var(--colorText);
        }

        & path {
          transition: var(--transitionStyle);
          stroke: var(--colorText);
        }
      }
    }

    & > svg {
      transition: var(--transitionStyle);
      width: 18px;
      height: 18px;
      transform: rotate(266deg);

      & circle {
        transition: var(--transitionStyle);
        stroke: var(--colorText);
      }

      & path {
        transition: var(--transitionStyle);
        stroke: var(--colorText);
      }
    }
  }
}

.footerBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .clearFilter {
    height: 50px;
  }

  & > button {
    width: auto;
    line-height: 120%;
    height: 50px;
  }

  /* position: relative;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 24px;
  background-color: var(--colorText);
  box-shadow: var(--boxShadowTop);
  z-index: 100;
  & > button {
    margin: 0 !important;
  } */
}

.clearFilter {
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorText);
  border: solid 1px var(--colorGrey100);
  color: var(--colorBlack);
  line-height: 130%;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-right: 10px;
    border-radius: 100px;
    line-height: 100%;
    font-size: 30px;
    padding: 0;
    width: 46px;
    height: 46px;
    border: none;
    background-color: rgb(243, 243, 242);
  }

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
    text-decoration: none;
  }
}

.descriptionContainer {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 700;
  color: #a49d9f;
}

.petsDescription {
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  margin-top: 6px;
}

.locationAddress {
  padding: 0;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding: 0 0px 0 24px;
  }
  & > div {
    width: 100%;
    & > form {
      width: 100%;
    }
  }

  & input {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    width: 100%;
  }
}

.rightSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0.1;

  @media (--viewportMedium) {
    position: relative;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 6px;

      @media (--viewportSmall) {
        margin-right: 10px;
      }

      @media (--viewportMedium) {
        margin-right: 20px;
      }
    }
  }

  & .calendarSection {
    @media (--viewportMedium) {
      position: relative;
    }

    & .calendarBtn {
      border: none;
      padding: 0;
      cursor: pointer;
      transition: var(--transitionStyle);

      &.selected {
        & > svg {
          & path {
            fill: var(--marketplaceColor);
          }
        }
      }

      & > svg {
        transition: var(--transitionStyle);
        width: 24px;
        height: 24px;

        @media (--viewportMedium) {
          width: 30px;
          height: 30px;
        }

        & path {
          fill: #000;
        }
      }

      &:hover {
        transition: var(--transitionStyle);

        & > svg {
          transition: var(--transitionStyle);
          transform: scale(1.2);
        }
      }
    }
  }

  & .vehicleSection {
    /* position: relative; */
    & .vehicleBtn {
      border: none;
      padding: 0;
      cursor: pointer;
      transition: var(--transitionStyle);

      &.selected {
        & > svg {
          & path {
            fill: var(--marketplaceColor);
          }
        }
      }

      & > svg {
        transition: var(--transitionStyle);
        width: 24px;
        height: 24px;

        @media (--viewportMedium) {
          width: 34px;
          height: 34px;
        }

        & path {
          fill: var(--colorGrey700);
        }
      }

      &:hover {
        transition: var(--transitionStyle);

        & > svg {
          transition: var(--transitionStyle);
          transform: scale(1.2);
        }
      }
    }

    & .filterBtn {
      border: none;
      padding: 0;
      cursor: pointer;
      transition: var(--transitionStyle);

      &.selected {
        & > svg {
          & path {
            fill: var(--marketplaceColor);
          }
        }
      }

      & > svg {
        transition: var(--transitionStyle);
        width: 24px;
        height: 24px;

        @media (--viewportMedium) {
          width: 30px;
          height: 30px;
        }

        /* & path {
          fill: var(--colorGrey700);
        } */
      }

      &:hover {
        /* transition: var(--transitionStyle); */

        & > svg {
          /* transition: var(--transitionStyle); */
          /* transform: scale(1.08) rotate(90deg); */
        }
      }
    }

    & .vehicleDropdown {
      position: absolute;
      top: calc(100% + 12px);
      right: 0;
      left: 0;
      background-color: var(--colorText);
      box-shadow: var(--boxShadow);
      min-width: 300px;
      border-radius: var(--borderRadiusMedium);
      padding: 10px 0;

      @media (--viewportMedium) {
        right: 0;
        left: auto;
      }

      & .vehicleDropdownContent {
        max-height: 300px;
        overflow-y: auto;

        & > label {
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:hover {
            cursor: pointer;
          }

          & > input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          & > span {
            font-size: 15px;
            line-height: 120%;
            transition: var(--transitionStyle);
            color: var(--colorGrey600);
            cursor: pointer;

            &:hover {
              transition: var(--transitionStyle);
              color: var(--colorGrey800);
            }
          }
        }
      }
    }
  }

  & .searchBtn {
    border-radius: 100px;
    width: 35px;
    height: 35px;
    min-height: auto;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      width: 45px;
      height: 45px;
    }

    & > svg {
      width: 15px;
      height: 15px;
      transform: rotate(270deg);
      margin: 0 auto;

      @media (--viewportMedium) {
        width: 18px;
        height: 18px;
      }

      & > g {
        stroke: var(--colorText);
      }
    }
  }
}

.calendarBtnActive,
.vehicleBtn {
  color: var(--marketplaceColor);
}

.dateRangePicker {
  position: absolute;

  right: 0;
  left: 0;
  top: calc(100% + 12px);
  background-color: var(--colorText);
  z-index: 10;
  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadowFilterButton);

  @media (--viewportMedium) {
    left: auto;
    right: 0;
    top: calc(100% + 28px);
  }
}

.dropdownButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  & span {
    font-size: 14px;
    line-height: 110%;
    color: var(--colorGrey300);
    transition: var(--transitionStyle);
    padding: 14px 10px;
    display: inline-block;

    &:hover {
      transition: var(--transitionStyle);
      color: var(--colorBlack);
      cursor: pointer;
    }
  }

  & .leftBtns {
  }

  & .rightBtns {
    & > span {
      &.submitBtn {
        color: var(--marketplaceColor);
        transition: var(--transitionStyle);

        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColorDark);
          cursor: pointer;
        }
      }
    }
  }
}

.iconSpinner {
  position: relative;
  top: 0;
  right: 12px;
  display: flex;
  align-items: center;
}
