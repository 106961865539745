@import '../../../styles/customMediaQueries.css';

.locationElements {
  display: flex;
  gap: 10px;
}

.showGeneralAmenities {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 15px auto 0;
  padding: 0 24px;
  gap: 10px;
  @media (--viewportLarge) {
    gap: 15px;
  }
  & .amenityBlock {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 6);
    & > img {
      max-width: 100%;
      width: 150px;
    }
    & > p {
      margin: 0;
      margin-top: -20px;
      padding: 0;
      font-size: 18px;
      color: var(--colorText);
      font-weight: var(--fontWeightBold);
    }
  }
}
.imageGallary {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  &.imageGallary2 {
    & .gallarySec {
      order: 2;
    }
    & .sectionFilter {
      order: 1;
      padding-left: 0px;
      padding-right: 30px;
      @media (--viewportLarge) {
        padding-right: 60px;
      }
    }
  }
  & .gallarySec {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-gap: 10px;
    width: 50%;
    @media (--viewportLarge) {
      grid-gap: 14px;
    }
    & .imgBlock {
      cursor: pointer;
      border-radius: 10px;
      padding-bottom: 30%;
      position: relative;
      overflow: hidden;
      transition: var(--transitionStyle);
      display: grid;
      &:hover {
        transition: var(--transitionStyle);
        & > img {
          transform: scale(1.05);
        }
      }
      & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        transition: var(--transitionStyle);
      }
      & > h2 {
        color: var(--colorText);
        font-size: 30px;
        line-height: 130%;
        font-weight: var(--fontWeightBlack);
        margin: 0 0 10px 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        text-align: center;
        text-shadow: 0px 0px 8px #000000a3;
      }
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 4;
        grid-row-end: 6;
      }
      &:nth-child(6) {
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 6;
        grid-row-end: 8;
      }
    }
  }
  & .sectionFilter {
    width: 50%;
    padding-left: 30px;
    @media (--viewportLarge) {
      padding-left: 60px;
    }
    & > img {
      max-width: 100%;
      width: 200px;
      margin-bottom: 15px;
    }
    & > h2 {
      color: var(--marketplaceColorLightBg);
      font-size: 30px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;
    }
    & > p {
      color: #51565c;
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;
    }
    & > button {
      background-color: var(--marketplaceColorLightBg);
      border-color: var(--marketplaceColorLightBg);
      color: var(--colorText);
      padding: 15px 30px;
      border-radius: 100px;
      display: inline-block;
      text-align: center;
      font-size: 20px;
      line-height: 120%;
      width: auto;
      min-width: 200px;
      min-height: auto;
    }
  }
}
.moreAbout {
  position: relative;
  overflow-x: hidden;
  padding: 50px 0px;
  @media (--viewportMedium) {
    padding: 50px 0px;
  }
  /* & > * {
    padding: 50px 0px;
    @media (--viewportMedium) {
      padding: 50px 0px;
    }
  } */
  & .sectionListings {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
    min-height: 400px;
    & .sectionFilter {
      width: 40%;
      padding: 0 24px 0 0;
      @media (--viewportMedium) {
        padding: 0 24px;
      }
      @media (--viewportLargeWithPaddings) {
        width: 50%;
      }
      & > img {
        max-width: 100%;
        width: 200px;
        margin-bottom: 15px;
      }
      & > h2 {
        color: var(--colorText);
        font-size: 35px;
        line-height: 100%;
        font-weight: var(--fontWeightBlack);
        margin: 0 0 15px 0;
        padding: 0;
      }
      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        margin: 0 0 15px 0;
        padding: 0;
        text-align: justify;
        width: 76%;
      }
      & > button {
        background-color: var(--marketplaceColorDark);
        border-color: var(--marketplaceColorDark);
        color: var(--colorText);
        padding: 15px 30px;
        border-radius: 100px;
        display: inline-block;
        text-align: center;
        font-size: 20px;
        line-height: 120%;
        width: auto;
        min-width: 200px;
        min-height: auto;
      }
    }
    & .halfSlider {
      width: 60%;
      position: absolute;
      right: 0;
      @media (--viewportLargeWithPaddings) {
        width: 50%;
      }
      & :global(.slick-slider) {
        position: relative;
        & :global(.slick-list) {
          padding: 0 !important;
        }
        & :global(.slick-slide) {
          & :global(.slick-slider) {
            & :global(.slick-arrow) {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 100px;
              z-index: 1;
              width: 34px;
              height: 34px;
            }
            & :global(.slick-arrow.slick-disabled) {
              opacity: 0.5;
              cursor: not-allowed;
            }

            & :global(.slick-prev) {
              left: 10px;
              background-color: transparent;
            }
            & :global(.slick-next) {
              right: 10px;
              background-color: transparent;
            }
            & :global(.slick-slide) {
              padding: 0;
            }
          }
        }
      }
      & :global(.slick-prev),
      & :global(.slick-next) {
        width: 40px;
        height: 40px;
      }
      & :global(.slick-arrow) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100px;
        z-index: 1;
        background-color: var(--colorText);
        transition: var(--transitionStyle);
      }

      & :global(.slick-arrow.slick-disabled) {
        opacity: 0.5;
        cursor: not-allowed;
      }
      & :global(.slick-prev) {
        left: -50px;
      }
      & :global(.slick-next) {
        right: 30px;
      }
      & :global(.slick-prev:before),
      & :global(.slick-next:before) {
        font-size: 30px;
      }
    }
  }
}

.ourPartnerSection {
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  padding: 0px;
  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    line-height: 130%;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 10px 0;
    padding: 0;
    float: left;
    width: 100%;
  }
  & .seenOn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    float: left;
    width: 100%;
    & .partnerImg {
      background-color: var(--colorText);
      border-radius: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      width: 100px;
      min-height: 100px;
      &:not(:last-child) {
        margin-right: 10px;
        @media (--viewportLarge) {
          margin-right: 10px;
        }
      }
      & > img {
        max-width: 100%;
        width: 70px;
        margin: 0 auto;
        @media (--viewportMedium) {
          width: 100px;
        }
      }
    }
    &.partnerImages {
      gap: 20px;
      @media (--viewportLarge) {
        gap: 40px;
      }
      & .partnerImg {
        margin: 0;
      }
    }

    & .listingImg {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% / 6 - 15px);
      min-height: 80px;
      cursor: pointer;
      @media (--viewportLarge) {
        min-height: 100px;
      }
      &:not(:last-child) {
        margin-right: 15px;
      }
      & > img {
        max-width: 100%;
        width: 80px;
        @media (--viewportLarge) {
          width: 150px;
        }
      }
      & > h2 {
        color: var(--colorText);
        font-size: 18px;
        line-height: 130%;
        font-weight: var(--fontWeightBold);
        margin: -15px 0 0 0;
        padding: 0;
      }
    }
  }
}
:global(.slick-slider) {
  margin: 0 -10px;
  @media (--viewportLarge) {
    margin: 0 -15px;
  }
  :global(.slick-slider) {
    margin: 0;

    :global(.slick-slide) {
      padding: 0;

      :global(.slick-arrow) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100px;
        background-color: var(--colorText);
        z-index: 1;
        box-shadow: var(--boxShadow);
        transition: var(--transitionStyle);
        &:hover {
          transition: var(--transitionStyle);
          transform: scale(1.2);
        }
      }
      :global(.slick-arrow.slick-disabled) {
        opacity: 0.5;
        cursor: not-allowed;
      }

      :global(.slick-prev) {
        left: 0px;
      }
      :global(.slick-prev:before) {
        content: ' ';
        background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        background-size: 100%;
      }
      :global(.slick-next) {
        right: 0px;
      }
      :global(.slick-next:before) {
        content: ' ';
        background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB0cmFuc2Zvcm09InJvdGF0ZSgxODApIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        background-size: 100%;
      }
    }
  }
}
:global(.slick-slide) {
  padding: 10px 10px;
}
:global(.slick-arrow) {
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

& :global(.slick-arrow.slick-disabled) {
  opacity: 0.5;
  cursor: not-allowed;
}
:global(.slick-prev) {
  left: 30px;
}
:global(.slick-next) {
  right: 30px;
}

:global(.slick-prev:before),
:global(.slick-next:before) {
  display: none;
}
.fullSlider {
  width: 100%;
  &.whiteBgSection {
    & .showGeneralAmenities {
      & .amenityBlock {
        & > p {
          color: var(--marketplaceColorDark);
          font-size: 20px;
          line-height: 130%;
          font-weight: var(--fontWeightSemiBold);
          margin: -15px 0 0 0;
          padding: 0;
        }
      }
    }
  }
  & :global(.slick-arrow.slick-disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.sliderItem {
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--boxShadowNotFoundPageSearch);
  min-height: 400px;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  & .sliderItemContent {
    position: absolute;
    background-color: #6ea36099;
    width: 100%;
    border-radius: 20px;
    padding: 14px 20px;
    bottom: 0;
    left: 0;
    text-align: center;
    & > h2 {
      color: var(--colorText);
      font-size: 35px;
      font-weight: var(--fontWeightBlack);
      line-height: 120%;
      padding: 0;
      margin: 0 0 4px 0;
    }
    & > p {
      color: var(--colorText);
      font-size: 20px;
      line-height: 130%;
      /* font-weight: var(--fontWeightSemiBold); */
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      & > span {
        font-weight: 600;
      }
    }
    & > button {
      background-color: transparent;
      border: solid 2px var(--colorText);
      color: var(--colorText);
      font-size: 20px;
      line-height: 120%;
      padding: 8px 20px;
      display: inline-block;
      text-align: center;
      width: auto;
      min-height: auto;
      height: auto;
      border-radius: 100px;
      cursor: pointer;
    }
  }
}

.howItWorksContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    font-weight: var(--fontWeightBlack);
    line-height: 130%;
    padding: 0;
    margin: 0 0 4px 0;
    text-align: center;
  }
  & .locationElements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-bottom: 15px;

    & .location {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      & .imageWrapper {
        & > img {
          max-width: 100%;
          width: 200px;
        }
      }
      & > h2 {
        margin: 0 0 15px 0;
        line-height: 100%;
        padding: 0;
        color: var(--marketplaceColorLightBg);
        font-size: 30px;
        font-weight: var(--fontWeightBlack);
      }
      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 120%;
        text-align: justify;
        width: auto;
        font-weight: var(--fontWeightRegular);
        margin: 0;
        padding: 0;
      }
    }
  }
  & .searchBtn {
    background-color: #3d632978;
    border: solid 2px var(--colorText);
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: var(--colorText);
    font-size: 20px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 15px;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColor);
      color: var(--colorText);
    }
  }
}

.campsiteContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 100%;
    padding: 0;
    margin: 0 0 15px 0;
  }
  & > p {
    color: var(--colorText);
    font-size: 20px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
    padding: 0;
    max-width: 50%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  & .searchBtn {
    background-color: #3d63299e;
    border: solid 2px var(--colorText);
    padding: 15px 40px;
    border-radius: 100px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: auto;
    color: var(--colorText);
    font-size: 20px;
    line-height: 130%;
    font-weight: var(--fontWeightBold);
    letter-spacing: 0.3px;
    margin-top: 15px;
    transition: var(--transitionStyle);
    min-width: 200px;
    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColor);
      color: var(--colorText);
    }
  }
}

.becomeHostContent {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 100%;
    padding: 0;
    margin: 0 0 15px 0;
  }
  & .featureSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    margin: 50px 0 10px;
    & .featureBlock {
      text-align: center;
      width: 100%;
      & > svg {
        margin-bottom: 15px;
      }
      & > h2 {
        color: var(--marketplaceColorLightBg);
        font-size: 30px;
        font-weight: var(--fontWeightBlack);
        line-height: 100%;
        padding: 0;
        margin: 0 0 15px 0;
      }
      & > p {
        color: var(--colorText);
        font-size: 20px;
        line-height: 150%;
        font-weight: var(--fontWeightRegular);
        padding: 0;
        max-width: 80%;
        width: 100%;
        text-align: center;
        margin: 0 auto 10px;
      }
    }
  }
  & .bottomButtonsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    & > button {
      background-color: #3d63299e;
      border: solid 2px var(--colorText);
      padding: 15px 20px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      color: var(--colorText);
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 10px;
      transition: var(--transitionStyle);
      @media (--viewportLarge) {
        padding: 15px 40px;
      }
      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }
      &.whiteBorderBtn {
        background-color: transparent;
        color: var(--colorText);
        &:hover {
          transition: var(--transitionStyle);
          text-decoration: none;
          background-color: var(--marketplaceColor);
          color: var(--colorText);
        }
      }
    }
  }
}
.newsletterSec {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 100%;
    padding: 0;
    margin: 0 0 15px 0;
  }
  & .newsletterForm {
    max-width: 60%;
    width: 100%;
    margin: 0 auto;
    & input {
      background-color: var(--colorText);
      border-radius: 100px;
      padding: 0px 30px;
      min-height: 60px;
    }
    & > button {
      background-color: transparent;
      color: var(--colorText);
      border: solid 2px var(--colorText);
      padding: 15px 40px;
      border-radius: 100px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: auto;
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightBold);
      letter-spacing: 0.3px;
      margin-top: 15px;
      transition: var(--transitionStyle);
      min-width: 200px;
      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColor);
        color: var(--colorText);
      }
    }
  }
}

.campingInContent {
  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto 47px;
    text-align: center;
  }
  & :global(.slick-arrow) {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: var(--colorText);
  }
  & :global(.slick-arrow.slick-disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & :global(.slick-prev) {
    left: 30px;
  }
  & :global(.slick-next) {
    right: 30px;
  }
  & .sliderItem {
    padding-bottom: 66.66%;
    position: relative;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: var(--transitionStyle);
    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: var(--transitionStyle);
    }
    & > h2 {
      color: var(--colorText);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-shadow: 0px 0px 8px #000000a3;
    }
  }
}
.recentCampingTitle {
  text-align: center;
  color: var(--colorText);
  font-size: 35px;
  line-height: 130%;
  font-weight: var(--fontWeightBlack);
  margin: 0px 0 22px 0;
  padding: 0;
}
.popularSearchLabel {
  text-align: center;
  color: var(--colorText);
  font-size: 30px;
  line-height: 130%;
  font-weight: var(--fontWeightBlack);
  margin: 30px 0 10px 0;
  padding: 0;
}

.popularSearchLabelWhiteBg {
  color: #7dac70;
}

.markdownTitle {
  & p {
    margin: 0 !important;
  }
  & a {
    color: white !important;
  }
}
.renderLocationDescription {
  position: relative;

  & > h2 {
    margin: 0 0 15px 0;
    line-height: 100%;
    padding: 0;
    color: var(--marketplaceColorLightBg);
    font-size: 30px;
    font-weight: var(--fontWeightBlack);
  }
  & > p {
    color: var(--colorText);
    font-size: 20px;
    line-height: 120%;
    text-align: justify;
    width: auto;
    font-weight: var(--fontWeightRegular);
    margin: 0;
    padding: 0;
  }
}
