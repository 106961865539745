@import '../../styles/customMediaQueries.css';

.sticky {
}

.sectionTitle {
  composes: h2 from global;
}

.sectionSubHeading {
  composes: sectionSubHeading from global;
}

.pageContent {
}

.loaderSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  opacity: 0.85;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5) 0%, #051036 72%);

  & .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.halfSliderSection {
  background-color: var(--marketplaceColorLightBg);
  position: relative;
  z-index: 0;
  /* padding: 50px 0px;
  @media (--viewportMedium) {
    padding: 50px 0px;
  } */
}

.fullSliderSection {
  background-color: var(--colorText);
  padding: 50px 0px 0;
  overflow-x: hidden;

  @media (--viewportMedium) {
    padding: 50px 0px;
  }

  @media (max-width: 767px) {
    padding: 50px 0px;
  }

  &.greenBgSection {
    background-color: var(--marketplaceColorLightBg);
    overflow-x: hidden;
  }

  &.imageGallarySectionMobile {
    padding-left: 0;
    padding-right: 0;
  }

  &.seoMobileSection {
    padding-top: 50px;

    @media (--viewportMedium) {
      padding-top: 0;
    }

    @media (max-width: 767px) {
      & > div {
        &:first-child {
          margin-bottom: 50px;
          margin-top: 0;
        }
      }
    }
  }
}

.howItWorkSection {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/2.+Concept-min.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 24px 0;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    padding: 50px 24px;
  }
}

.imageGallarySection {
  padding: 50px 24px 0;
  background-color: var(--colorText);

  @media (--viewportMedium) {
    padding: 50px 24px;
  }

  &.imageGallarySectionMobile {
    background-color: var(--marketplaceColorLightBg);
    padding: 0px 24px !important;

    @media (max-width: 767px) {
      padding-top: 50px !important;
    }
  }
}

.compositeSection {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/3.+Nature+Campground-min.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 24px;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 50px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  &::before {
    background-color: #00000016;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    content: '';
    left: 0;
    top: 0;
  }

  @media screen and (min-width: 325px) and (max-width: 768px) {
    background-image: url('./Asset/blogMobile.jpg');
  }

  @media screen and (min-width: 768px) and (max-width: 1920px) {
    background-image: url('https://dih6yo2fd8n78.cloudfront.net/6.+Blog-min.jpg');
  }

  &.compositeSectionMobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.becomeHostSection {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/4.+Become+Host-min.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 50px 24px;
  margin-top: 50px;

  @media (--viewportMedium) {
    margin-top: 0;

    padding: 50px 24px;
  }
}

.newsletterSection {
  background-image: url('https://dih6yo2fd8n78.cloudfront.net/newletterDesktop.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100dvh;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 24px;

  @media screen and (min-width: 325px) and (max-width: 768px) {
    background-image: url('./Asset/newsletterMobile.jpg');
  }

  @media screen and (min-width: 768px) and (max-width: 1920px) {
    background-image: url('https://dih6yo2fd8n78.cloudfront.net/5.+Newsletter-min.jpg');
  }

  @media (--viewportMedium) {
    padding: 50px 24px;
  }
}

.campingInSection {
  background-color: var(--marketplaceColorLightBg);
  padding: 30px 0px;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    padding: 50px 0px;
  }

  @media (--viewportMedium) {
    padding: 30px 0px;
  }
}

.parkingSection {
  position: relative;
  z-index: 0;
}

.howToBecomeHostSection {
  position: relative;
  z-index: 0;
  margin-top: 50px;

  @media (--viewportMedium) {
    margin-top: 0px;
  }

  &::after {
    position: absolute;
    z-index: 0;
    background-color: var(--marketplaceColorLightBg);
    height: 22%;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
  }
}

.exploreBlog {
  background-image: url('./Asset/blogMobile.jpg');

  @media (max-width: 768px) {
    background-image: url('./Asset/blogMobile.jpg') !important;
  }
}
